import React from "react";
import { RemoteTable } from "@package/table";

const Churchs = () => {
  const _columns = [
    {
      dataIndex: "",
      title: "名称",
    },
    {
      dataIndex: "",
      title: "所属教区",
    },
    {
      dataINdex: "",
      title: "所属地区",
    },
    {
      dataIndex: "",
      title: "电话号码",
    },
    {
      dataIndex: "",
      title: "地址",
    },
    {
      dataIndex: "",
      title: "邮政编码",
    },
    {
      dataIndex: "",
      title: "操作",
    },
  ];

  return <RemoteTable columns={_columns}></RemoteTable>;
};

export default Churchs;
