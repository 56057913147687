const routes = [
  {
    path: "/friendlink",
    menuName: "友情链接管理",
    icon: "LinkOutlined",
    element: () => import("./app"),
    sort: 7,
    // layout: false,
  },
];

export default routes;
