export default [
  {
    value: 1,
    label: "目录",
  },
  {
    value: 2,
    label: "文章类",
  },
  {
    value: 3,
    label: "文档下载",
  },
  {
    value: 4,
    label: "公告类",
  },
  {
    value: 5,
    label: "图片浏览",
  },
  {
    value: 6,
    label: "图片新闻",
  },
  {
    value: 7,
    label: "音乐类",
  },
  {
    value: 8,
    label: "影视类",
  },
  {
    value: 9,
    label: "论坛类",
  },
  {
    value: 10,
    label: "链接类",
  },
  {
    value: 11,
    label: "系统类",
  },
];
