import React, { useEffect, useRef, useState } from "react";
import { IFormItemProps, NormalForm } from "@package/form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDispatchAsync,
  useLocalDispatch,
} from "@src/javascripts/common/useDispatch";
import {
  GetMediaUploadProps,
  GetUploadProps,
  GetUploadUrl,
} from "@src/javascripts/common/uploadUtil";
import { Col, Row, message, Button } from "antd";
import { frontUrl, ResponseCode } from "@src/global/layout";
import dayjs from "dayjs";
import token from "@src/javascripts/common/storage/token";
import useTableCallback from "@src/javascripts/common/useTableCallback";

const EditChurchNews = () => {
  const param = useParams();
  const navigate = useNavigate();
  const _ref = useRef<any>();
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Promise.all([
      useLocalDispatch({
        type: "article/AllArticleCategories",
      }),
      useLocalDispatch({
        type: "article/AllArticleTags",
      }),
    ]).then((result) => {
      // console.log(res,'res---')

      if (result[0].code === ResponseCode.Success) {
        setCategories(result[0].data);
      }
      if (result[1].code === ResponseCode.Success) {
        setTags(result[1].data);
      }
      if (param?.id) {
        setLoading(true);
        useLocalDispatch({
          type: "article/FindArticle",
          data: {
            id: param?.id,
          },
          callback: (res) => {
            if (res.code === ResponseCode.Success)
              _ref.current.setFieldsValue({
                ...res.data,
                articleCover: res.data.articleCover
                  ? [{ id: param.id, url: res.data.articleCover }]
                  : null,
                articleMedia: res.data.articleMedia
                  ? [
                      {
                        id: param.id,
                        name: res.data.articleMedia,
                        url: res.data.articleMedia,
                      },
                    ]
                  : null,
                articleTags:
                  res.data.articleTags.map((item) => {
                    const _item = result[1]?.data?.find(
                      (tag) => tag.articleTagId == item
                    );

                    return {
                      // value: item,
                      label: _item.articleTagName,
                      value: _item.articleTagId,
                      key: item,
                    };
                  }) || [],
              });

            setLoading(false);
          },
        });
      }
    });

    // useLocalDispatch({
    //   type: "article/AllArticleCategories",
    //   callback: (res) => {
    //     if (res.code === ResponseCode.Success) {
    //       setCategories(res.data);
    //     }
    //   },
    // });

    // useLocalDispatch({
    //   type: "article/AllArticleTags",
    //   callback: (res) => {
    //     if (res.code === ResponseCode.Success) {
    //       setTags(res.data);
    //     }
    //   },
    // });
  }, []);

  const _formItems: Array<IFormItemProps> = [
    {
      formItemProps: {
        name: "articleId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        label: "标题",
        name: "articleTitle",
        rules: [
          {
            required: true,
          },
        ],
      },
    },
    {
      formItemProps: {
        label: "作者",
        name: "articleAuthor",
      },
      formControlProps: {
        maxLength: 30,
      },
    },
    {
      type: "treeselect",
      formItemProps: {
        label: "类型",
        name: "articleType",
        rules: [
          {
            required: true,
          },
        ],
      },
      formControlProps: {
        treeData: (categories ?? [])
          .filter((item) => !item.articleCategoryParentId)
          .map((item) => {
            return {
              ...item,
              children:
                categories.filter(
                  (subItem) =>
                    subItem.articleCategoryParentId === item.articleCategoryId
                ) ?? [],
            };
          }),
        fieldNames: {
          label: "articleCategoryName",
          value: "articleCategoryId",
        },
      },
    },
    {
      type: "select",
      formItemProps: {
        label: "标签",
        name: "articleTags",
      },
      formControlProps: {
        options: tags,
        fieldNames: {
          label: "articleTagName",
          value: "articleTagId",
        },
        labelInValue: true,
        mode: "tags",
        optionFilterProp: "label",
        filterOption: (inputValue, option) => {
          return option?.articleTagName?.indexOf?.(inputValue) >= 0;
        },
      },
    },
    {
      type: "date",
      formItemProps: {
        label: "生效时间",
        name: "articleEffectiveTime",
      },
    },
    {
      type: "switch",
      formItemProps: {
        label: "在Banner中显示",
        name: "articleOnBanner",
        initialValue: false,
      },
      formControlProps: {
        checkedChildren: "是",
        unCheckedChildren: "否",
        defaultChecked: false,
      },
    },
    {
      type: "upload",
      formItemProps: {
        label: "封面图片",
        name: "articleCover",
      },
      formControlProps: {
        listType: "picture-card",
        text: "上传封面图片",
        max: 1,
        ...GetUploadProps({
          accept: [".png", ".jpg", ".jpeg", ".gif"],
        }),
        imgCrop: {
          showGrid: true,
          aspectSlider: true,
          rotationSlider: true,
          aspect: 4 / 3,
          showReset: true,
        },
      },
    },
    {
      type: "switch",
      formItemProps: {
        label: "封面显示在内容中",
        name: "showOnContent",
        initialValue:true
      },
      formControlProps: {
        checkedChildren: "显示",
        unCheckedChildren: "不显示",
      },
    },
    {
      type: "textarea",
      formItemProps: {
        label: "简介",
        name: "description",
        initialValue: "",
      },
    },
    {
      type: "upload",
      formItemProps: {
        label: "多媒体文件",
        name: "articleMedia",
        extra: <>音频mp3文件，视频mp4文件</>,
      },
      formControlProps: {
        ...GetMediaUploadProps({
          accept: [".mp3", ".mp4", ".webm"],
          maxCount: 1,
          itemRender: (originNode, file, currFileList) => {
            if (file.status === "error") return originNode;

            return (
              // <div style={{paddingTop:"10px"}}>
              //   {/.mp3$/.test(file.name)? (
              //     <audio src={file.url} controls></audio>
              //   ) : (
              //     originNode
              //   )}
              // </div>
              <>{originNode}</>
            );
          },
        }),
      },
    },
    {
      type: "editor",
      formItemProps: {
        label: "内容",
        name: "articleContent",
        rules: [
          {
            required: true,
          },
        ],
      },
      formControlProps: {
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: GetUploadUrl({
            editor: true,
          }),
          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            "X-CSRF-TOKEN": "CSRF-Token",
            Authorization: token.get(),
          },
        },
      },
    },
  ];

  const saveData = async (data, preview = false) => {
    setLoading(true);
    const _res = await useDispatchAsync({
      type: !!data.articleId ? "article/UpdateArticle" : "article/AddArticle",
      data: {
        ...data,
        articleEffectiveTime: data.articleEffectiveTime
          ? dayjs(data.articleEffectiveTime).format("YYYY/MM/DD")
          : null,
        articleCover: data.articleCover?.[0]?.url,
        articleMedia: data.articleMedia?.[0]?.url,
        articleTags: data.articleTags ? JSON.stringify(data.articleTags) : null,
        description: data.description
          ? decodeURIComponent(data.description)
          : null,
      },
    });

    setLoading(false);
    if (_res.code === ResponseCode.Success) {
      message.success("保存成功");
      //预览功能
      if (preview) {
        window.open(
          `${frontUrl}/detail/${_res.data.articleId}?preview=1`,
          "_blank"
        );

        navigate(`/article/edit/${_res.data.articleId}`, {
          replace: true,
        });

        _ref.current.setFieldValue("articleId", _res.data.articleId);
      } else navigate("/article/list");
      return;
    }

    message.error(_res.message);
  };

  return (
    <Row style={{ display: "flex", justifyContent: "center" }}>
      <Col xs={24} xxl={16} md={18}>
        <div
          style={{
            background: "#fff",
            borderRadius: "6px",
            padding: "40px 20px",
            marginBottom: "60px",
          }}
        >
          <NormalForm
            ref={_ref}
            loading={loading}
            formItems={_formItems}
            labelCol={{ xs: { span: 24 }, sm: { span: 6 }, xxl: { span: 4 } }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 18 },
              xxl: { span: 20 },
            }}
            cancelButtonText="返回"
            onCancel={() => {
              navigate("/article/list");
            }}
            actionButtonsPosition={"fixed"}
            onSave={saveData}
            actionButtons={(buttons, formInstance) => {
              return (
                <>
                  {[
                    ...[
                      <Button
                        key="preview_button"
                        type="primary"
                        onClick={async () => {
                          await formInstance.validateFields();

                          saveData(formInstance.getFieldsValue(), true);
                        }}
                      >
                        保存并预览
                      </Button>,
                    ],
                    ...buttons,
                  ]}
                </>
              );
            }}
          ></NormalForm>
        </div>
      </Col>
    </Row>
  );
};

export default EditChurchNews;
