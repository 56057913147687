const routes = [
  {
    path: "/churchs",
    menuName: "教堂列表",
    icon: "CalendarOutlined",
    element: () => import("./list"),
    sort: 4,
    // layout: false,
    menu: true,
  },
];

export default routes;
