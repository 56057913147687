import { IPageModel } from "@package/store";

const _article = {
  Post: {
    ArticleList: "/backapi/article/list",
    FindArticle: "/backapi/article/find",
    UpdateArticle: "/backapi/article/update",
    AddArticle: "/backapi/article/add",
    DeleteArticle: "/backapi/article/delete",
    DeployArticle: "/backapi/article/deploy",
    ShowOnTop: "/backapi/article/onTop",
    ShowOnBanner: "/backapi/article/onBanner",
    OffArticle: "/backapi/article/off",
  },
};

const _category = {
  Post: {
    ArticleCategoryList: "/backapi/article_category/list",
    // FindArticle: "/backapi/article_category/find",
    UpdateArticleCategory: "/backapi/article_category/update",
    AddArticleCategory: "/backapi/article_category/add",
    DeleteArticleCategory: "/backapi/article_category/delete",
    ChangeStatus: "/backapi/article_category/changeStatus",
    ChangePosition:"/backapi/article_category/changePosition"
  },
  Get: {
    AllArticleCategories: "/backapi/article_category/all",
    // DeployArticle: "/backapi/article_category/deploy"
  },
};

const _tag = {
  Post: {
    ArticleTagList: "/backapi/article_tag/list",
    UpdateArticleTag: "/backapi/article_tag/update",
    AddArticleTag: "/backapi/article_tag/update",
    DeleteArticleTag: "/backapi/article_tag/delete",
  },
  Get: {
    AllArticleTags: "/backapi/article_tag/all",
  },
};

const _material = {
  Post: {
    MaterialList: "/backapi/material/list",
    FindMaterial: "/backapi/material/find",
    UpdateMaterial: "/backapi/material/update",
    AddMaterial: "/backapi/material/add",
    DeleteMaterial: "/backapi/material/delete",
    DeployMaterial: "/backapi/material/deploy",
    OffMaterial: "/backapi/material/off",
    EnableMaterial: "/backapi/material/enable",
  },
};

export default {
  namespace: "article",
  actionType: {
    Post: {
      ..._category.Post,
      ..._article.Post,
      ..._tag.Post,
      ..._material.Post,
    },
    Get: {
      ..._category.Get,
      ..._tag.Get,
    },
  },
} as IPageModel;
