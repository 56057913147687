import { IPageModel } from "@package/store";

export default {
  namespace: "fl",
  actionType: {
    Post: {
      QueryLinks: "/backapi/link/list",
      AddLink: "/backapi/link/add",
      UpdateLink: "/backapi/link/update",
      DeleteLink: "/backapi/link/delete",
      ChangeStatus: "/backapi/link/changeStatus"
    },
    Get: {
      TestLink: "/backapi/link/test"
    }
  },
} as IPageModel;
