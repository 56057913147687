const routes = [
  {
    path: "/article",
    menuName: "资讯文章",
    icon: "MenuHomeOutlined",
    // element: () => import("./app"),
    sort: 2,
    children: [
      {
        path: "list",
        menuName: "文章列表",
        index: true,
        element: () => import("./list"),
      },
      {
        path: "add",
        menuName: "添加文章",
        menu: false,
        element: () => import("./list/edit"),
      },
      {
        path: "edit/:id?",
        menuName: "修改文章",
        menu: false,
        element: () => import("./list/edit"),
      },
      {
        path: "categories",
        menuName: "文章分类列表",
        element: () => import("./category/index"),
      },
      {
        path: "tags",
        menuName: "文章标签列表",
        element: () => import("./tag/index"),
      },
      {
        path: "materials",
        menuName: "历史资料",
        element: () => import("./material"),
      },
      {
        path: "edit_material",
        menuName: "添加历史资料",
        menu: false,
        element: () => import("./material/edit"),
      },
      {
        path: "edit_material/:id",
        menuName: "修改历史资料",
        menu: false,
        element: () => import("./material/edit"),
      },
    ],
  },
];

export default routes;
