export default [
  {
    value: 1,
    label: "超级管理员",
    code: "superadmin",
  },
  {
    value: 2,
    label: "管理员",
    code: "admin",
  },
  {
    value: 3,
    label: "内容编辑员",
    code: "editor",
  },
];
