import { useLocalDispatch } from "./useDispatch";
import { ResponseCode } from "@src/global/layout";
import { message } from "antd";
import { ILoadDataParam, ITableCallback } from "@package/table";

interface ITableResponse {
  code: number;
  data?: ITableListResult | null;
  msg: string;
  success: boolean;
}
interface ITableListResult {
  rows?: Array<any>;
  records?: Array<any>;
  total: number;
  pageSize: number;
  page: number;
}

export default function useTableCallback(res: ITableResponse) {
  if (res.code !== ResponseCode.Success) {
    message.error(res.msg);
  }

  const { rows, records, total = 0, pageSize = 15, page = 1 } = res.data || {};

  return {
    data: rows ?? records ?? [],
    pagination: {
      total: total,
      current: page,
      pageSize,
    },
  };
}

export const useDelTableRecord =
  (url: string) => (data?: any, callback?: (data?: any) => void) => {
    useLocalDispatch({
      type: url,
      data,
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          message.success("删除成功");
          callback?.(res.data);
          return;
        }

        message.error(res.msg);
      },
    });
  };

export const useLoadTableData =
  (requestType, normalizeSearchParam?: any) =>
  (params: ILoadDataParam, callback: ITableCallback) => {
    const { pageSize, current: page } = params.pagination;
    useLocalDispatch({
      type: requestType,
      data: {
        pageSize,
        page,
        ...(normalizeSearchParam
          ? normalizeSearchParam(params.searchParams || {})
          : params.searchParams || {}),
      },
      callback: (res) => {
        callback(useTableCallback(res));
      },
    });
  };

interface ITableTreeDataProps {
  normalizeSearchParam?: (data?: any) => any;
  onSuccess?: (data?: any) => any;
}

export const useLoadTableTreeData =
  (
    requestType: string,
    { normalizeSearchParam, onSuccess }: ITableTreeDataProps
  ) =>
  (params: ILoadDataParam, callback: ITableCallback) => {
    const { pageSize, current: page } = params.pagination;
    useLocalDispatch({
      type: requestType,
      data: {
        pageSize,
        page,
        ...(normalizeSearchParam
          ? normalizeSearchParam(params.searchParams || {})
          : params.searchParams || {}),
      },
      callback: (res) => {
        let _res = res;

        if (onSuccess) {
          _res = onSuccess(res);
        }

        callback(useTableCallback(_res));
      },
    });
  };
