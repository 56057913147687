import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, ModalForm } from "@package/form";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { message } from "antd";
import calcSortPosition from "@src/javascripts/common/calcSortPosition";

const EditCategory = (props, ref) => {
  const _ref = useRef<any>();
  const _columns: Array<IFormItemProps> = [
    {
      formItemProps: {
        name: "articleCategoryId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "articleCategoryName",
        label: "分类名称",
        rules: [{ required: true }],
      },
    },
    {
      type: "select",
      formItemProps: {
        name: "articleCategoryParentId",
        label: "上级分类",
      },
      formControlProps: {
        options: (props.categories ?? [])
          .filter((item) => !item.articleCategoryParentId)
          .map((item) => ({
            label: item.articleCategoryName,
            value: item.articleCategoryId,
          })),
      },
    },
    {
      type: "switch",
      formItemProps: {
        name: "articleCategoryOnMenu",
        label: "在菜单中显示",
        initialValue: false,
      },
    },
    {
      type: "inputnumber",
      formItemProps: {
        name: "articleCategorySort",
        label: "位置",
      },
    },
    {
      type: "switch",
      formItemProps: {
        name: "articleCategoryIdentity",
        label: "实名验证",
        initialValue: false,
      },
    },
  ];

  const saveData = async (data) => {
    if (!data.articleCategoryId) {
      data.articleCategorySort = calcSortPosition(
        props.table.getData()?.data?.length > 0
          ? props.table.getData()?.data[props.table.getData()?.data?.length - 1]
              ?.articleCategorySort || 0
          : 0
      );
    }
    const _res = await useDispatchAsync({
      type: data.articleCategoryId
        ? "article/UpdateArticleCategory"
        : "article/AddArticleCategory",
      data: data,
    });

    if (_res.code === ResponseCode.Success) {
      message.success(`分类【${data.articleCategoryName}】保存成功！`);
      props?.onSave?.();
      return;
    }

    return _res.message;
  };

  useImperativeHandle(ref, () => _ref.current, []);

  return (
    <ModalForm
      title={"添加/编辑文章分类"}
      ref={_ref}
      formItems={_columns}
      onOk={saveData}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
    ></ModalForm>
  );
};

export default React.forwardRef(EditCategory);
