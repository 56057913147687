import React from "react";
import { RemoteTable } from "@package/table";
import {
  useDelTableRecord,
  useLoadTableData,
} from "@src/javascripts/common/useTableCallback";
import { Button, Popconfirm, Space, Switch, Tag, message } from "antd";
import { RenderIcon } from "@package/icons";
import { Link, useNavigate } from "react-router-dom";
import EnumArticleStatus, {
  EnumArticleStatusDesc,
} from "../common/EnumArticleStatus";
import { useLocalDispatch } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { EnumParish } from "@src/javascripts/common/enums/EnumParish";

const MaterialList = () => {
  const _navigate = useNavigate();
  const [table] = RemoteTable.useTable();

  const _columns = [
    {
      title: "资料名称",
      dataIndex: "materialTitle",
      filter: true,
    },
    {
      title: "作者",
      dataIndex: "materialAuthor",
      filter: true,
    },
    {
      title: "所属教区",
      dataIndex: "materialType",
      render: (data, record) => {
        return EnumParish[data]||"其它";
      },
    },
    {
      title: "状态",
      dataIndex: "materialStatus",
      filter: true,
      type: "select",
      formControlProps: {
        options:
          Object.entries(EnumArticleStatus).map((item) => {
            return {
              label: item[1],
              value: item[0],
            };
          }) || [],
      },
      render: (data, record) => {
        if (record.isDeleted) {
          return <Tag color="red"> 已删除</Tag>;
        }
        return (
          <Switch
            checked={EnumArticleStatusDesc.DEPLOY === data}
            checkedChildren={EnumArticleStatus[data] ?? data}
            unCheckedChildren={EnumArticleStatus[data] ?? data}
            onChange={() => changeStatus(record)}
          ></Switch>
        );
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <Space>
            <Link
              to={`/backapi/material/view/${record.materialId}`}
              target="_blank"
            >
              <RenderIcon type="EyeOutlined"></RenderIcon>&nbsp;预览
            </Link>
            <Button
              type="link"
              icon={<RenderIcon type="EditOutlined" />}
              onClick={() => _edit(record.materialId)}
            >
              修改
            </Button>
            {record.isDeleted ? (
              <>
                <Popconfirm
                  title={`确定要启用历史资料【${record.materialTitle}】?`}
                  onConfirm={() => {
                    useDelTableRecord("article/EnableMaterial")(
                      {
                        id: record.materialId,
                      },
                      (res) => {
                        table.reload();
                      }
                    );
                  }}
                >
                  <Button
                    type="link"
                    icon={<RenderIcon type="CheckOutlined" />}
                  >
                    启用
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <Popconfirm
                title={`确定要删除历史资料【${record.materialTitle}】?`}
                onConfirm={() => {
                  useDelTableRecord("article/DeleteMaterial")(
                    {
                      id: record.materialId,
                    },
                    (res) => {
                      // console.log(res, "--res");
                      // if (res.code === ResponseCode.Success) {
                      table.reload();
                      // return;
                      // }
                      // message.error(res.msg);
                    }
                  );
                }}
              >
                <Button
                  type="link"
                  icon={<RenderIcon type="DeleteOutlined" />}
                  danger
                >
                  删除
                </Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const _edit = (id?: number) => {
    if (id) _navigate(`/article/edit_material/${id}`);
    else _navigate(`/article/edit_material`);
  };

  const changeStatus = (record) => {
    useLocalDispatch({
      type:
        record.materialStatus === EnumArticleStatusDesc.DEPLOY
          ? "article/OffMaterial"
          : "article/DeployMaterial",
      data: {
        id: record.materialId,
      },
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          message.success(record.materialStatus ? "发布成功" : "下架成功");
          table.reload();
          return;
        }
        message.error(res.msg);
      },
    });
  };

  return (
    <RemoteTable
      rowKey={"materialId"}
      table={table}
      columns={_columns}
      loadData={useLoadTableData("article/MaterialList")}
      extraFilterItems={[
        {
          type: "radio",
          formItemProps: {
            label: "显示",
            name: "isDeleted",
          },
          formControlProps: {
            options: [
              { label: "全部", value: "0" },
              { label: "正常", value: "2" },
              { label: "已删除", value: "1" },
            ],
          },
        },
      ]}
      actionBar={
        <Button
          type="primary"
          icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
          onClick={() => _edit()}
        >
          新增
        </Button>
      }
    ></RemoteTable>
  );
};

export default MaterialList;
