import React, { useEffect, useRef, useState } from "react";
import { RemoteTable } from "@package/table";
import { RenderIcon } from "@package/icons";
import {
  useDelTableRecord,
  useLoadTableData,
} from "@src/javascripts/common/useTableCallback";
import {
  Button,
  Space,
  Popconfirm,
  message,
  Tooltip,
  Switch,
  Tag,
  Descriptions,
} from "antd";
import { useNavigate } from "react-router-dom";
import EnumArticleStatus from "../common/EnumArticleStatus";
import { useLocalDispatch } from "@src/javascripts/common/useDispatch";
import { frontUrl, ResponseCode } from "@src/global/layout";
import { formatDate } from "@src/javascripts/common/dateUtil";

const ArticleList = () => {
  const [table] = RemoteTable.useTable();
  const _editRef = useRef<any>();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    useLocalDispatch({
      type: "article/AllArticleCategories",
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          setCategories(res.data);
        }
      },
    });
  }, []);

  const _columns = [
    // { title: "编号", dataIndex: "articleId" },
    {
      title: "标题",
      dataIndex: "articleTitle",
      filter: true,
      // fixed: true,
      ellipsis: {
        showTitle: false,
      },
      render: (data, record) => {
        return (
          <Space direction="horizontal">
            <Tooltip title={data} placement="topLeft">
              <span style={{maxWidth:"620px",whiteSpace:"nowrap",textOverflow:"ellipsis",display:"block",overflow:"hidden"}}>{data}</span>
            </Tooltip>

            <Button
              type="link"
              icon={<RenderIcon type="EyeOutlined"></RenderIcon>}
              href={`${frontUrl}/detail/${record.articleId}?preview=1`}
              target="_blank"
            >
              预览
            </Button>
          </Space>
        );
      },
    },
    {
      title: "作者",
      dataIndex: "articleAuthor",
    },
    {
      title: "类型",
      dataIndex: "articleType",
      type: "treeselect",
      width:140,
      filter: true,
      formControlProps: {
        treeData: categories
          .filter((item) => !item.articleCategoryParentId)
          .map((item) => {
            return {
              ...item,
              children:
                categories.filter(
                  (subItem) =>
                    subItem.articleCategoryParentId === item.articleCategoryId
                ) ?? [],
            };
          }),
        fieldNames: {
          label: "articleCategoryName",
          value: "articleCategoryId",
        },
      },
      render: (data) => {
        return (
          categories.find((item) => item.articleCategoryId === data)
            ?.articleCategoryName ?? data
        );
      },
    },
    {
      title: "显示在Banner",
      dataIndex: "articleOnBanner",
      width: 120,
      render: (data, record) => {
        return (
          <Switch
            checked={data}
            onClick={() => showOnBanner(data, record)}
          ></Switch>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "articleStatus",
      width: 90,
      type: "select",
      filter: true,
      formControlProps: {
        options: Object.entries(EnumArticleStatus).map((item) => ({
          label: item[1],
          value: item[0],
        })),
      },
      render: (data) => {
        switch (data) {
          case 1:
            return <Tag color="green">{EnumArticleStatus[data]}</Tag>;
          case 2:
            return <Tag color="red">{EnumArticleStatus[data]}</Tag>;
          default:
            return <Tag color="blue">{EnumArticleStatus[data]}</Tag>;
        }
      },
    },
    {
      title: "标签",
      dataIndex: "articleTags",
      render: (data, record) => {
        return (
          <Space direction="vertical">
            {data.map((item) => {
              return <span key={item.articleTagId}>{item.articleTagName}</span>;
            })}
          </Space>
        );
      },
    },
    {
      title: "操作",
      fixed: "right",
      render: (data, record) => {
        return (
          <Space size={"small"}>
            {record.articleStatus === 1 && (
              <Button
                size="small"
                type="link"
                onClick={() => _offArticle(data)}
                icon={<RenderIcon type="SendOutlined"></RenderIcon>}
                danger
              >
                下架
              </Button>
            )}
            {record.articleStatus !== 1 && (
              <Button
                size="small"
                type="link"
                onClick={() => _deploy(data)}
                icon={<RenderIcon type="SendOutlined"></RenderIcon>}
              >
                {record.articleStatus === 2 ? "确认修改" : "发布"}
              </Button>
            )}

            <Button
              size="small"
              type="link"
              onClick={() => {
                changeStatus(record.articleOnTop, record);
              }}
              icon={
                <RenderIcon
                  type={
                    record.articleOnTop
                      ? "VerticalAlignBottomOutlined"
                      : "VerticalAlignTopOutlined"
                  }
                ></RenderIcon>
              }
            >
              {record.articleOnTop ? "取消置顶" : "置顶"}
            </Button>
            <Button
              size="small"
              type="link"
              icon={<RenderIcon type="EditOutlined"></RenderIcon>}
              onClick={() => edit(record)}
            >
              编辑
            </Button>
            <Popconfirm
              title={`确定删除文章【${record.articleTitle}】`}
              onConfirm={() =>
                useDelTableRecord("article/DeleteArticle")(
                  {
                    id: record.articleId,
                  },
                  () => table.reload()
                )
              }
            >
              <Button
                type="link"
                size="small"
                danger
                icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
              >
                删除
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const edit = (data?: any) => {
    if (data?.articleId) {
      navigate(`/article/edit/${data.articleId}`);
    } else {
      navigate("/article/add");
    }
  };

  const _deploy = (data) => {
    useLocalDispatch({
      type: "article/DeployArticle",
      data: {
        id: data.articleId,
      },
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          message.success(`文章【${data.articleTitle}】发布成功！`);
          table.reload();
          return;
        }

        message.error(res.message);
      },
    });
  };

  const _offArticle = (data) => {
    useLocalDispatch({
      type: "article/OffArticle",
      data: {
        id: data.articleId,
      },
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          message.success(`文章【${data.articleTitle}】已下线`);
          table.reload();
          return;
        }

        message.error(res.message);
      },
    });
  };

  const changeStatus = (status, data?: any) => {
    useLocalDispatch({
      type: "article/ShowOnTop",
      data: {
        id: data.articleId,
        articleOnTop: !status,
      },
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          message.success(`文章【${data.articleTitle}】置顶成功！`);
          table.reload();
          return;
        }
        message.error(res.message);
      },
    });
  };

  const showOnBanner = (status, data?: any) => {
    useLocalDispatch({
      type: "article/ShowOnBanner",
      data: {
        id: data.articleId,
        articleOnBanner: !status,
      },
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          message.success(`文章【${data.articleTitle}】状态更改成功！`);
          table.reload();
          return;
        }
        message.error(res.message);
      },
    });
  };

  return (
    <>
      <RemoteTable
        rowKey={"articleId"}
        table={table}
        scroll={{ x: true }}
        loadData={useLoadTableData("article/ArticleList")}
        columns={_columns}
        extraFilterItems={[
          {
            type: "daterange",
            formItemProps: {
              label: "创建时间",
              name: "createTime",
            },
          },
          {
            type: "daterange",
            formItemProps: {
              label: "发布时间",
              name: "articleDeployDate",
            },
          },
        ]}
        expandable={{
          expandedRowRender: (record, ...args) => {
            // console.log(record, args);
            return (
              <>
                <Descriptions layout="vertical">
                  <Descriptions.Item label="创建人">
                    {record.createUser}
                  </Descriptions.Item>
                  <Descriptions.Item label="创建时间">
                    {formatDate(record.createTime)}
                  </Descriptions.Item>
                  <Descriptions.Item label="修改人">
                    {record.updateUser}
                  </Descriptions.Item>
                  <Descriptions.Item label="修改时间">
                    {formatDate(record.updateTime)}
                  </Descriptions.Item>
                  <Descriptions.Item label="发布人">
                    {record.articleDeployUser}
                  </Descriptions.Item>
                  <Descriptions.Item label="发布时间">
                    {formatDate(record.articleDeployDate)}
                  </Descriptions.Item>
                  <Descriptions.Item span={2} label="简介">
                    {decodeURIComponent(record.description||"")}
                  </Descriptions.Item>
                </Descriptions>
              </>
            );
          },
        }}
        actionBar={
          <Button
            type="primary"
            icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
            onClick={() => edit()}
          >
            新增
          </Button>
        }
      ></RemoteTable>
    </>
  );
};

export default ArticleList;
