import React, { useEffect, useRef } from "react";
import { RemoteTable } from "@package/table";
import {
  useDelTableRecord,
  useLoadTableData,
} from "@src/javascripts/common/useTableCallback";
import { Button, Popconfirm, Space, Switch } from "antd";
import { RenderIcon } from "@package/icons";
import EditFL from "./edit";
import { useLocalDispatch } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";

const OtherLinks = () => {
  const [table] = RemoteTable.useTable();
  const editRef = useRef<any>();

  const _columns = [
    // {
    //   title: "编号",
    //   dataIndex: "linkId",
    // },
    {
      title: "友链名称",
      dataIndex: "linkTitle",
    },
    {
      title: "友链地址",
      dataIndex: "linkUrl",

      render: (data) => {
        return (
          <a href={data} target="_blank">
            {data}
          </a>
        );
      },
    },
    {
      title: "是否显示",
      dataIndex: "isAvailable",
      render: (data,record) => {
        return <Switch checked={data} onChange={(value) => {
          changeStatus(value,record)
        }}></Switch>;
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <Space>
            <Button
              type="link"
              icon={<RenderIcon type="EditOutlined"></RenderIcon>}
              onClick={() => {
                edit(record);
              }}
            >
              编辑
            </Button>
            <Popconfirm
              title={`确定要删除友链【${record.linkTitle}】?`}
              onConfirm={() =>
                useDelTableRecord("fl/DeleteLink")(
                  {
                    id: record.linkId,
                  },
                  table.reload
                )
              }
            >
              <Button
                type="link"
                icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
                danger
              >
                删除
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const changeStatus = (value,record) => {
    // console.log(value, '---value')
    useLocalDispatch({
      type:"fl/ChangeStatus",
      data:{
        id:record.linkId,
        isAvailable:!record.isAvailable
      },
      callback:(res)=>{
        if(res.code===ResponseCode.Success){
          table.reload();
        }
      }
    })
  }

  const edit = (data?: any) => {
    editRef.current.setVisible(true);

    if (data?.linkId) {
      editRef.current.setFieldsValue(data);
    }
  };

  return (
    <>
      <RemoteTable
        rowKey={"linkId"}
        table={table}
        columns={_columns}
        loadData={useLoadTableData("fl/QueryLinks")}
        scroll={{x:true}}
        actionBar={
          <>
            <Button
              type="primary"
              onClick={() => edit()}
              icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
            >
              添加
            </Button>
          </>
        }
      ></RemoteTable>
      <EditFL ref={editRef} onSave={() => table.reload()}></EditFL>
    </>
  );
};

export default OtherLinks;
