import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app";

import "antd/dist/antd.css";
import "@package/table/src/index.scss";
import "./styles/app.scss";

const _app = createRoot(document.getElementById("root"), {
  identifierPrefix: "cczj_",
  onRecoverableError: (...args) => {
    console.log(args, "error");
  },
});

_app.render(<App></App>);
// _app.render(<Form></Form>);
