import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ILoadDataParam, RemoteTable } from "@package/table";
import {
  useDelTableRecord,
  useLoadTableData,
  useLoadTableTreeData,
} from "@src/javascripts/common/useTableCallback";
import { Button, Popconfirm, Space, Switch, message } from "antd";
import { RenderIcon } from "@package/icons";
import EditCategory from "./edit";
import { useLocalDispatch } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { DndContext } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  useSortable,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import calcSortPosition from "@src/javascripts/common/calcSortPosition";

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);

  return (
    <Button
      type="text"
      size="small"
      icon={<RenderIcon type="HolderOutlined" />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    ></Button>
  );
};

const Row: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props["data-row-key"] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const ExpandedRowRender = ({
  record,
  index,
  indent,
  expanded,
  columns,
  changePosition,
}) => {
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  // const [table] = RemoteTable.useTable();

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => `table-row-${record.articleCategoryId}` === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => `table-row-${record.articleCategoryId}` === over?.id
        );

        let _start = 0;
        let _end = 0;

        if (overIndex > 0)
          _start =
            prevState[overIndex - 1].articleCategorySort +
            prevState[overIndex - 1].articleCategoryId;

        _end =
          prevState[overIndex].articleCategorySort +
            prevState[overIndex].articleCategoryId || 0;

        console.log(_start, _end, "--start end", prevState[overIndex]);

        let _pos = calcSortPosition(_start, _end);

        //保存排序
        changePosition({
          id: prevState[activeIndex].articleCategoryId,
          position: _pos,
        });

        let _data = arrayMove(prevState, activeIndex, overIndex);

        return _data;
      });
    }
  };

  useEffect(() => {
    if (!!record?.subs) setDataSource(record.subs);
  }, [record]);

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={(dataSource || []).map(
          (i) => `table-row-${i.articleCategoryId}`
        )}
        strategy={verticalListSortingStrategy}
      >
        <RemoteTable
          rowKey={"articleCategoryId"}
          columns={columns}
          dataSource={dataSource ?? []}
          components={{ body: { row: Row } }}
          pagination={false}
          // table={table}
        ></RemoteTable>
      </SortableContext>
    </DndContext>
  );
};

const CategoryList = () => {
  const [table] = RemoteTable.useTable();
  const modalRef = useRef<any>();
  const [categories, setCategories] = useState([]);

  const loadCategories = () => {
    useLocalDispatch({
      type: "article/AllArticleCategories",
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          setCategories(res.data);
        }
      },
    });
  };

  const changePosition = (data) => {
    useLocalDispatch({
      type: "article/ChangePosition",
      data,
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          table.reload();
        }
      },
    });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const _columns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "分类名称",
      dataIndex: "articleCategoryName",
      filter: true,
    },
    {
      title: "在菜单中显示",
      dataIndex: "articleCategoryOnMenu",
      render: (data, record) => {
        return (
          <Switch
            defaultChecked={data}
            onClick={() => changeStatus(record)}
          ></Switch>
        );
      },
    },
    {
      title: "是否需要实名",
      dataIndex: "articleCategoryIdentity",
      render: (data, record) => {
        return (
          <Switch
            defaultChecked={data}
            onChange={() => changeIdentityStatus(record)}
          ></Switch>
        );
      },
    },
    {
      title: "位置",
      dataIndex: "articleCategorySort",

      render: (data, record) => {
        return !data ? "----" : data;
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <Space>
            <Button
              type="link"
              icon={<RenderIcon type="EditOutlined"></RenderIcon>}
              onClick={() => editCategory(record)}
            >
              编辑
            </Button>
            <Popconfirm
              title={`删除分类【${record.articleCategoryName}】？`}
              onConfirm={() => {
                useDelTableRecord("article/DeleteArticleCategory")(
                  { id: record.articleCategoryId },
                  () => table.reload()
                );
              }}
            >
              <Button
                type="link"
                icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
                danger
              >
                删除
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const _subColumns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "编号",
      dataIndex: "articleCategoryId",
    },
    {
      title: "分类名称",
      dataIndex: "articleCategoryName",
      // filter: true,
    },
    {
      title: "是否需要实名",
      dataIndex: "articleCategoryIdentity",
      render: (data, record) => {
        return (
          <Switch
            defaultChecked={data}
            onChange={() => changeIdentityStatus(record)}
          ></Switch>
        );
      },
    },
    {
      title: "位置",
      dataIndex: "articleCategorySort",
      render: (data, record) => {
        return !data ? "----" : data;
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <Space>
            <Button
              type="link"
              icon={<RenderIcon type="EditOutlined"></RenderIcon>}
              onClick={() => editCategory(record)}
            >
              编辑
            </Button>
            <Popconfirm
              title={`你确定要删除这个分类？`}
              onConfirm={() => {
                useDelTableRecord("article/DeleteArticleCategory")(
                  { id: record.articleCategoryId },
                  () => table.reload()
                );
              }}
            >
              <Button
                type="link"
                icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
                danger
              >
                删除
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const editCategory = (data?: any) => {
    modalRef.current.setVisible(true);

    if (data?.articleCategoryId) {
      modalRef.current.setFieldsValue(data);
    }
  };

  //是否显示在菜单中
  const changeStatus = (data?: any) => {
    useLocalDispatch({
      type: "article/ChangeStatus",
      data: {
        id: data.articleCategoryId,
      },
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          message.success(`分类【${data.articleCategoryName}】状态修改成功！`);
          table.reload();
          return;
        }

        message.error(res.message);
      },
    });
  };

  //是否开启实名
  const changeIdentityStatus = (data?: any) => {};

  const [dataSource, setDataSource] = React.useState<any[]>([]);

  const sortCategory = (id, position) => {
    console.log(id, position);
    changePosition({
      id,
      position,
    });
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => `table-row-${record.articleCategoryId}` === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => `table-row-${record.articleCategoryId}` === over?.id
        );

        let _start = 0;
        let _end = 0;

        if (overIndex > 0) {
          _start =
            prevState[overIndex - 1].articleCategorySort +
            prevState[overIndex - 1].articleCategoryId;

          _end =
            prevState[overIndex].articleCategorySort +
              prevState[overIndex].articleCategoryId || 0;
        }

        let _pos = calcSortPosition(_start, _end);

        //保存排序
        sortCategory(prevState[activeIndex].articleCategoryId, _pos);

        let _data = arrayMove(prevState, activeIndex, overIndex);

        table.setData({
          data: _data,
        });
        return _data;
      });
    }
  };

  useEffect(() => {
    if (table?.getData?.()?.data) {
      setDataSource(table?.getData?.()?.data);
    }
  }, [table?.getData?.()?.data]);

  return (
    <>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={(table?.getData?.()?.data || []).map(
            (i) => `table-row-${i.articleCategoryId}`
          )}
          strategy={verticalListSortingStrategy}
        >
          <RemoteTable
            columns={_columns}
            scroll={{ x: true }}
            expandable={{
              expandedRowRender: (record, index, indent, expanded) => {
                return (
                  <ExpandedRowRender
                    columns={_subColumns}
                    record={record}
                    index={index}
                    indent={indent}
                    expanded={expanded}
                    changePosition={changePosition}
                  />
                );
              },
              rowExpandable: (record) => {
                return record.subs.length > 0;
              },
            }}
            components={{ body: { row: Row } }}
            pagination={false}
            loadData={useLoadTableTreeData("article/ArticleCategoryList", {
              normalizeSearchParam: () => ({
                pageSize: 999,
              }),
              onSuccess: (res) => {
                if (res.code !== ResponseCode.Success) {
                  return {};
                }
                const mapCategory = (id?: any) => {
                  let _res = res.data.records.filter((item) => {
                    if (!id) return !item.articleCategoryParentId;

                    return item.articleCategoryParentId == id;
                  });

                  if (_res.length > 0) {
                    _res = _res.map((item) => {
                      return {
                        ...item,
                        subs: mapCategory(item.articleCategoryId),
                      };
                    });
                  }

                  return _res;
                };

                let _outputData = mapCategory();

                return {
                  ...res,
                  data: {
                    ...res.data,
                    records: _outputData,
                  },
                };
              },
            })}
            table={table}
            rowKey={"articleCategoryId"}
            actionBar={
              <Button
                type="primary"
                icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
                onClick={() => editCategory()}
              >
                添加分类
              </Button>
            }
          ></RemoteTable>
        </SortableContext>
      </DndContext>
      <EditCategory
        ref={modalRef}
        onSave={() => {
          table.reload();
          loadCategories();
        }}
        categories={categories}
        table={table}
      ></EditCategory>
    </>
  );
};

export default CategoryList;
