import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, NormalForm } from "@package/form";
import { EnumParish } from "@src/javascripts/common/enums/EnumParish";
import {
  GetUploadProps,
  GetUploadUrl,
} from "@src/javascripts/common/uploadUtil";
import token from "@src/javascripts/common/storage/token";

const BaseStep = (props, ref: React.MutableRefObject<any>) => {
  const _normalRef = useRef({} as any);
  const _formItems: IFormItemProps[] = [
    {
      formItemProps: {
        name: "materialId",
        label: "materialId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "materialTitle",
        label: "资料名称",
        rules: [{ required: true }],
      },
    },
    {
      formItemProps: {
        name: "materialAuthor",
        label: "作者",
        rules: [{ required: true }],
      },
    },
    {
      type: "date",
      formItemProps: {
        name: "materialWriteTime",
        label: "发表时间",
      },
    },
    {
      type: "select",
      formItemProps: {
        name: "materialType",
        label: "所属教区",
      },
      formControlProps: {
        options: Object.entries(EnumParish).map((item) => {
          return {
            label: item[1],
            value: item[0],
          };
        }),
      },
    },
    {
      type: "upload",
      formItemProps: {
        name: "materialCover",
        label: "封面",
      },
      formControlProps: {
        listType: "picture-card",
        max: 1,
        ...GetUploadProps({
          accept: [".png", ".jpg", ".jpeg", ".gif"],
        }),
        text: "上传封面图",
      },
    },
    {
      type: "editor",
      formItemProps: {
        name: "materialDescription",
        label: "简介",
      },
      formControlProps: {
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: GetUploadUrl({
            editor: true,
          }),

          // Enable the XMLHttpRequest.withCredentials property.
          // withCredentials: true,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            "X-CSRF-TOKEN": "CSRF-Token",
            authorization: token.get(),
          },
        },
      },
    },
  ];

  useImperativeHandle(
    ref,
    () => ({
      ..._normalRef.current,
      setFieldsValue: (data) => {
        if (data?.materialCover) {
          data["materialCover"] = [
            { id: new Date().getTime(), url: data["materialCover"] },
          ];
        }
        _normalRef.current.setFieldsValue(data);
      },
      getFieldsValue: () => {
        let _values = _normalRef.current.getFieldsValue();

        if (_values?.materialCover) {
          _values["materialCover"] = _values.materialCover[0].url;
        }
        return _values;
      },
    }),
    []
  );

  return (
    <NormalForm
      ref={_normalRef}
      formItems={_formItems}
      showActionBar={false}
    ></NormalForm>
  );
};

export default React.forwardRef(BaseStep);
