import React from "react";
import { useSelector } from "react-redux";
import Page403 from "@package/baselayout/src/Error/403";

interface IAuthRouter {
	auth?: any,
	path?: any
	children: React.ReactNode,
	shouldAuth?: boolean
}

const AuthRouter = ({ children, auth, path, shouldAuth = true }: IAuthRouter) => {

	if (!shouldAuth) return <>{children}</>

	const userInfo = useSelector((state: any) => state.global?.userInfo);


	if (auth?.(userInfo) || !auth)
		return <>{children}</>

	return <Page403></Page403>
};

export default AuthRouter;