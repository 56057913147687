export default {
  0: "待发布",
  1: "已发布",
  2: "已下线",
};

export const EnumArticleStatusDesc = {
  UNDEPLOY: 0,
  DEPLOY: 1,
  OFFLINE: 2,
};
