import { IActionType } from "@package/store";

const _actionType = {
  Post: {
    ScheduleList: "/backapi/daily_schedule/list",
    AddSchedule: "/backapi/daily_schedule/add",
    UpdateSchedule: "/backapi/daily_schedule/update",
    DeleteSchedule: "/backapi/daily_schedule/delete"
  },
} as IActionType;

export default {
  namespace: "daily_schedule",
  actionType: _actionType,
};
