import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, ModalForm } from "@package/form";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { Button, Divider, Input, Space, message } from "antd";
import EnumScheduleType from "./enum/EnumScheduleType";
import EnumSCheduleItemType from "./enum/EnumScheduleItemType";
import { RenderIcon } from "@package/icons";

const EventEdit = (props, ref) => {
  const _modalRef = useRef();

  const _formItems: Array<IFormItemProps> = [
    {
      formItemProps: {
        hidden: true,
        name: "scheduleId",
      },
    },
    {
      type: "select",
      formItemProps: {
        label: "事件类型",
        name: "scheduleType",
        initialValue: "signle",
      },
      formControlProps: {
        options: EnumScheduleType,
      },
    },
    {
      type: "date",
      formItemProps: {
        label: "日期",
        name: "scheduleDate",
        hidden: ({ scheduleType }) => {
          return scheduleType != "signle";
        },
      },
    },
    {
      type: "daterange",
      formItemProps: {
        label: "日期区间",
        name: "scheduleDateRange",
        hidden: ({ scheduleType }) => {
          return scheduleType != "range";
        },
      },
    },
    {
      type: "tablelist",
      formItemProps: {
        name: "scheduleItems",
        label: "事件列表",
        initialValue: [{}],
        rules: [{ required: true }]
      },
      children: [
        {
          type: "select",
          formItemProps: {
            name: "scheduleItemTitle",
            label: "标题",
            rules: [{ required: true }]
          },
          formControlProps: {
            options: EnumSCheduleItemType,
            style: {
              width: "160px",
            },
            dropdownRender: (menu) => {
              return (
                <>
                  {menu}
                  {/* <Divider style={{ margin: "4px 0" }} />
                  <Space style={{ padding: "0 4px 2px" }}>
                    <Input
                      placeholder="请输入标题"
                    //   ref={inputRef}
                    //   value={name}
                    //   onChange={onNameChange}
                    />
                    <Button
                      type="text"
                      size="small"
                      icon={<RenderIcon type="PlusOutlined" />}
                    //   onClick={addItem}
                    >
                      添加
                    </Button>
                  </Space> */}
                </>
              );
            },
          },
        },
        {
          type: 'textarea',
          formItemProps: {
            name: "scheduleItemContent",
            label: "内容",
            rules: [{ required: true }]
          },
        },
      ],
    },
  ];

  const _saveData = async (data) => {
    const { scheduleDateRange, ...restData } = data;
    console.log(data, '--data')
    let _data = restData;

    if (!!scheduleDateRange) {
      _data["scheduleStart"] = scheduleDateRange[0];
      _data["scheduleEnd"] = scheduleDateRange[1];
    }

    let _res = await useDispatchAsync({
      type: data.scheduleId
        ? "daily_schedule/UpdateSchedule"
        : "daily_schedule/AddSchedule",
      data: {
        ..._data
      },
    });

    if (_res.code === ResponseCode.Success) {
      message.success("添加成功");
      props?.onSave?.();
      return;
    }

    return _res.message;
  };

  useImperativeHandle(ref, () => _modalRef.current, []);

  return (
    <>
      <ModalForm
        title="添加/修改日历"
        width={860}
        ref={_modalRef}
        formItems={_formItems}
        onOk={_saveData}
      ></ModalForm>
    </>
  );
};

export default React.forwardRef(EventEdit);
