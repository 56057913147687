const routes = [
    {
      path: "/schedule",
      menuName: "日历",
      icon: "CalendarOutlined",
      element: () => import("./app"),
      sort: 4,
      // layout: false,
    },
  ];
  
  export default routes;
  