import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ITabFormItemProp, ITabFormProp, TabForm } from "@package/form";
import { Link, useNavigate, useParams } from "react-router-dom";
import BaseStep from "./base";
import ContentTab from "./content";
import {
  useDispatchAsync,
  useLocalDispatch,
} from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { Button, message } from "antd";
import type { DragEndEvent } from "@dnd-kit/core";

const EditMaterial = (props) => {
  const _params = useParams();
  const _navigate = useNavigate();
  let [materialData, setData] = useState({} as any);

  const _tabFormRef = useRef<any>();
  const [tabItems, setTabItems] = useState<ITabFormItemProp[]>([
    {
      label: "基础信息",
      key: "base_tab",
      closable: false,
      children: BaseStep,
    },
  ]);

  const _saveData = async (data, callback?: (res) => void) => {
    const _obj = data[0];
    const _contents = data.slice(1);

    const _res = await useDispatchAsync({
      type: _obj.materialId ? "article/UpdateMaterial" : "article/AddMaterial",
      data: {
        ..._obj,
        contents: (_contents || []).map((item, index) => {
          return {
            ...item,
            orderIndex: index + 1,
          };
        }),
      },
    });

    if ((_res.code = ResponseCode.Success)) {
      message.success("保存成功！");
      if (callback) {
        callback(_res.data);
      } else _navigate("/article/materials");
      return;
    }
    message.warn(_res.msg);
    return;
  };

  const _findData = (id) => {
    useLocalDispatch({
      type: "article/FindMaterial",
      data: {
        id,
      },
      callback: (res) => {
        if (res.code === ResponseCode.Success) {
          const _data = res.data;
          const { contents, ...restData } = _data;
          setData(_data);
          if (contents.length > 0)
            _tabFormRef.current.genTabItems([
              restData,
              ...(contents || []).map((item) => ({
                label: item.materialItemTitle,
                key: item.materialItemId,
                forceRender: true,
                ...item,
              })),
            ]);
          setTimeout(() => {
            _tabFormRef.current.setFieldsValue([restData, ...(contents || [])]);
          });
          return;
        }
        message.error(res.msg);
      },
    });
  };

  useEffect(() => {
    if (_params.id) {
      _findData(_params.id);
    }
  }, []);

  return (
    <TabForm
      addComponent={ContentTab}
      type="editable-card"
      items={tabItems}
      onCancel={() => {
        _navigate("/article/materials");
      }}
      saveData={_saveData}
      actionButtonsPosition="fixed"
      extraButtons={(buttons) => {
        return [
          buttons[0],
          <Button
            key="btn-temp-save"
            type="primary"
            onClick={async () => {
              let _isValid = await _tabFormRef.current.validateFields();

              if (_isValid) {
                _saveData(_tabFormRef.current.getFieldsValue(), (res) => {
                  _navigate(`/article/edit_material/${res}`, { replace: true });
                  if (!!_params.id) _findData(res);
                });
              }
            }}
          >
            暂存
          </Button>,
          buttons[1],
          <>
            {materialData.materialId && (
              <Link
                key="lk_view"
                className="ant-btn ant-btn-primary"
                to={`/backapi/material/view/${materialData.materialId}`}
                target="_blank"
              >
                查看
              </Link>
            )}
          </>,
        ];
      }}
      ref={_tabFormRef}
    ></TabForm>
  );
};

export default EditMaterial;
