import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { ModalForm } from "@package/form";
import { message } from "antd";
import { useSelector } from "react-redux";
import { ResponseCode } from "@src/global/layout";

const EditModal = React.forwardRef((props: any, ref) => {
  const { save, title } = props;
  const modalRef = useRef<any>();
  // const [data, setData] = useState();
  const userInfo = useSelector((state: any) => state.global?.userInfo);

  const onSave = async (formData) => {
    // save(data);
    const res = await window.$store.dispatchAsync({
      type: "user/UserPwdUpdate",
      data: { ...formData, userId: userInfo.userId },
    });
    if (res.code === ResponseCode.Success) {
      props?.onLogout?.();
      return true;
    }

    message.error(res.message);
    return res.message;
  };
  useImperativeHandle(
    ref,
    () => ({
      show: () => modalRef.current?.setVisible?.(true),
      // setData: setData,
    }),
    []
  );

  // useEffect(() => {
  //   modalRef?.current?.setFieldsValue(data ?? {});
  // }, [data]);

  const formItems = [
    {
      formItemProps: {
        label: "旧密码",
        name: "oldPassword",
        rules: [{ required: true }],
      },
      formControlProps: {
        type: "password",
      },
    },
    {
      formItemProps: {
        label: "新密码",
        name: "newPassword",
        rules: [{ required: true }],
      },
      formControlProps: {
        type: "password",
      },
    },
    {
      formItemProps: {
        label: "确认密码",
        dependencies: ["newPassword"],
        name: "confirmPassword",
        rules: [
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("newPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("两次密码输入不一致!"));
            },
          }),
        ],
      },
      formControlProps: {
        type: "password",
      },
    },
  ];

  return (
    <ModalForm
      title={"修改密码"}
      formItems={formItems}
      ref={modalRef}
      width={600}
      onOk={onSave}
    ></ModalForm>
  );
});

export default EditModal;
