import React, { createContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const MaterialContext = createContext<IMaterialContext>({
  // edit:()=>void
} as any);

const materialProvider = ({ children }) => {
  const _navigate = useNavigate();
  const _params = useParams();

  const edit = (id: number) => {
    if (id) _navigate(`/article/edit_material/${id}`);
    else _navigate(`/article/edit_material`);
  };

  return (
    <MaterialContext.Provider value={{ edit }}>
      {children}
    </MaterialContext.Provider>
  );
};

export default materialProvider;

interface IMaterialContext {
  edit: (id: number) => void;
}
