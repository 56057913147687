import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, ModalForm } from "@package/form";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { message } from "antd";

const EditFL = (prop, ref) => {
  const _ref = useRef<any>();

  useImperativeHandle(
    ref,
    () => {
      return _ref.current;
    },
    []
  );

  const _formItems: Array<IFormItemProps> = [
    {
      formItemProps: {
        name: "linkId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "linkTitle",
        label: "网站名称",
        rules: [{ required: true }]
      },
    },
    {
      formItemProps: {
        name: "linkUrl",
        label: "网站地址",
        extra: <>网站地址以：http或https开头</>,
        rules: [{ required: true }]
      },
    },
    {
      type: "switch",
      formItemProps: {
        name: "isAvailable",
        label: "是否显示",
        initialValue: false
      },
    },
  ];

  const saveData = async (data) => {
    const _res = await useDispatchAsync({
      type: data.linkId ? "fl/UpdateLink" : "fl/AddLink",
      data: data,
    });

    if (_res.code === ResponseCode.Success) {
      message.success("保存成功");
      prop?.onSave?.();
      return;
    }

    return _res.message;
  };

  return (
    <ModalForm
      formItems={_formItems}
      ref={_ref}
      title="添加/编辑友链"
      onOk={saveData}
      width={720}
    ></ModalForm>
  );
};

export default React.forwardRef(EditFL);
