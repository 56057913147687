
const SORT_DEFAULT_INCR_FACT = 65536;

export default (startPosition = 0, afterPosition = 0) => {
	let finalPos = 0;

	console.log(startPosition, '--start', afterPosition, '---after')

	if (startPosition <= 0) {
		finalPos = afterPosition / 2
	};

	const _isCenter = (!!startPosition && !!afterPosition)

	if (_isCenter) {
		finalPos = (startPosition + afterPosition) / 2;
	}

	if (!afterPosition) {
		finalPos = startPosition + SORT_DEFAULT_INCR_FACT
	}

	return finalPos;
}