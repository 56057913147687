import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, ModalForm } from "@package/form";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { message } from "antd";

const EditTag = (props, ref) => {
	const _ref = useRef<any>();
	const _columns: Array<IFormItemProps> = [
		{
			formItemProps: {
				name: "articleTagId",
				hidden: true,
			},
		},
		{
			formItemProps: {
				name: "articleTagName",
				label: "标签名称",
				rules: [{ required: true }]
			},
		}
	];

	const saveData = async (data) => {
		const _res = await useDispatchAsync({
			type: data.articleTagId
				? "article/UpdateArticleTag"
				: "article/AddArticleTag",
			data: data,
		});

		if (_res.code === ResponseCode.Success) {
			message.success(`标签【${data.articleTagName}】保存成功！`)
			props?.onSave?.();
			return;
		}
		console.log(_res, '---')
		return _res.message;
	};

	useImperativeHandle(ref, () => _ref.current, []);

	return (
		<ModalForm
			title={"添加/编辑文章标签"}
			ref={_ref}
			formItems={_columns}
			onOk={saveData}
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 18 }}
		></ModalForm>
	);
};

export default React.forwardRef(EditTag);
