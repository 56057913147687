import React, { useImperativeHandle, useRef } from "react";
import { IFormItemProps, NormalForm } from "@package/form";
import {
  GetUploadProps,
  GetUploadUrl,
} from "@src/javascripts/common/uploadUtil";
import token from "@src/javascripts/common/storage/token";

const MaterialContent = (props, ref) => {
  const _normalRef = useRef({
    wrapperValues: function () {
      console.log("wrapper value");
    },
  } as any);
  const _formItems: IFormItemProps[] = [
    {
      formItemProps: {
        name: "materialItemId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "materialItemTitle",
        label: "标题",
        rules: [{ required: true }],
      },
    },
    {
      type: "upload",
      formItemProps: {
        name: "materialItemAdditional",
        label: "上传附件",
      },
      formControlProps: {
        listType: "picture-card",
        max: 1,
        ...GetUploadProps({
          accept: [".png", ".jpg", ".jpeg", ".gif"],
        }),
        text: "上传封面图",
      },
    },
    {
      type: "editor",
      formItemProps: {
        name: "materialItemContent",
        label: "内容",
        rules: [{ required: true }],
      },
      formControlProps: {
        simpleUpload: {
          uploadUrl: GetUploadUrl({
            editor: true,
          }),
          headers: {
            "X-CSRF-TOKEN": "CSRF-Token",
            authorization: token.get(),
          },
        },
      },
    },
  ];
  useImperativeHandle(
    ref,
    () => ({
      ..._normalRef.current,
      setFieldsValue: (data) => {
        if (data?.materialItemAdditional) {
          data["materialItemAdditional"] = [
            { id: new Date().getTime(), url: data["materialItemAdditional"] },
          ];
        }
        _normalRef.current.setFieldsValue(data);
      },
      getFieldsValue: () => {
        let _values = _normalRef.current.getFieldsValue();

        if (_values?.materialItemAdditional) {
          _values["materialItemAdditional"] =
            _values.materialItemAdditional[0].url;
        }
        return _values;
      },
    }),
    []
  );
  return (
    <NormalForm
      ref={_normalRef}
      formItems={_formItems}
      showActionBar={false}
      onValuesChange={(current, formValue) => {
        console.log(current, "--cur", current.materialItemTitle);
        if (!!current.hasOwnProperty("materialItemTitle")) {
          let { index, items, setItems } = props;

          let _item = {
            ...items[index],
            label: current.materialItemTitle || "【未命名】",
          };
          items[index] = _item;

          setItems([...items]);
        }
      }}
    ></NormalForm>
  );
};

export default React.forwardRef(MaterialContent);
