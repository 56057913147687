import React, { useEffect, useRef, useState } from "react";
import { RemoteTable } from "@package/table";
import {
	useDelTableRecord,
	useLoadTableData
} from "@src/javascripts/common/useTableCallback";
import { Button, Popconfirm, Space, Switch, message } from "antd";
import { RenderIcon } from "@package/icons";
import EditTag from "./edit";
import { useLocalDispatch } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import dayjs from "dayjs";

const TagList = () => {
	const [table] = RemoteTable.useTable();
	const modalRef = useRef<any>();
	const [tags, setTags] = useState([]);

	useEffect(() => {
		useLocalDispatch({
			type: "article/AllArticleTags",
			callback: (res) => {
				if (res.code === ResponseCode.Success) {
					setTags(res.data);
				}
			},
		});
	}, []);

	const _columns = [
		// {
		// 	title: "编号",
		// 	dataIndex: "articleTagId",
		// },
		{
			title: "标签名称",
			dataIndex: "articleTagName",
			filter: true,
		},
		{
			title: "创建时间",
			dataIndex: "createTime",
			render:(data)=>{
				return dayjs(data).format("YYYY年MM月DD日 HH:mm:ss")
			}
		},
		{
			title: "更新时间",
			dataIndex: "updateTime",
			render:(data)=>{
				return dayjs(data).format("YYYY年MM月DD日 HH:mm:ss")
			}
		},
		{
			title: "操作",
			render: (data, record) => {
				return (
					<Space>
						<Button
							type="link"
							icon={<RenderIcon type="EditOutlined"></RenderIcon>}
							onClick={() => editTag(record)}
						>
							编辑
						</Button>
						<Popconfirm title={`删除标签【${record.articleTagName}】？`} onConfirm={() => useDelTableRecord("article/DeleteArticleTag")({
							id: record.articleTagId
						}, () => table.reload())}>
							<Button
								type="link"
								icon={<RenderIcon type="DeleteOutlined"></RenderIcon>}
								danger
							>
								删除
							</Button>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	const editTag = (data?: any) => {
		modalRef.current.setVisible(true);

		if (data?.articleTagId) {
			modalRef.current.setFieldsValue(data);
		}
	};

	return (
		<>
			<RemoteTable
				columns={_columns}
				scroll={{ x: true }}
				loadData={useLoadTableData("article/ArticleTagList")}
				table={table}
				rowKey={"articleTagId"}
				actionBar={
					<Button
						type="primary"
						icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
						onClick={() => editTag()}
					>
						添加标签
					</Button>
				}
			></RemoteTable>
			<EditTag
				ref={modalRef}
				onSave={() => table.reload()}
				categories={tags}
			></EditTag>
		</>
	);
};

export default TagList;
